export const defaultEnvironment = {
  APP_NAME: 'talent-search-ui',
  BUILDKITE_BRANCH: 'master',
  BUILDKITE_BUILD_NUMBER: '0.0.0-dev',
} as const;

export const getEnvironment = (key: keyof typeof defaultEnvironment) => {
  const value = process.env[key];
  if (!value) {
    if (process.env.NODE_ENV === 'ci') {
      throw new Error(`Undefined environment variable: ${key}`);
    }

    return defaultEnvironment[key];
  }

  return value;
};
