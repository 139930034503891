import { Strong, Box } from 'braid-design-system';
import { useRef, useState, useEffect } from 'react';
import Transition from 'react-transition-group/Transition';

import { Tags } from '../../../types';
import type { AnimationState } from '../Animate/types';

import formatNumber from './formatNumber';

import * as styles from './Amount.css';

export const ANIMATION_TIMEOUT_MS = 200;

interface Props {
  value: number | null | undefined;
}

function Amount({ value }: Props) {
  const prevValue = useRef(value);
  const nodeRef = useRef(null);
  const [isAnimating, setAnimate] = useState(false);
  const [formattedValue, setFormattedValue] = useState(formatNumber(value));

  useEffect(() => {
    let animation: any;

    if (prevValue.current !== value) {
      setAnimate(true);
      prevValue.current = value;

      animation = setTimeout(() => {
        setAnimate(false);
        setFormattedValue(formatNumber(value));
      }, ANIMATION_TIMEOUT_MS);
    }

    return () => {
      if (animation) {
        clearTimeout(animation);
      }
    };
  }, [value]);

  return (
    <Transition nodeRef={nodeRef} in={isAnimating} timeout={500}>
      {(state: AnimationState) => (
        <Box
          display="inlineBlock"
          className={styles[state]}
          data-cy={Tags.Data.CreditsBalance}
        >
          <Strong>{formattedValue}</Strong>
        </Box>
      )}
    </Transition>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Amount;
