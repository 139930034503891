import { useEffect, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Transition from 'react-transition-group/Transition';

import useMediaQuery from '../../../hooks/useMediaQuery';
import useScrollbar from '../../../hooks/useScrollbar';

import Context from './Context';
import Content from './components/Content';
import Overlay from './components/Overlay';
import getPortalRoot from './getPortalRoot';
import { type Theme, type OpenFrom, Timeout } from './types';

interface ChildrenProps {
  onClose: () => void;
}

interface Props {
  isOpen: boolean;
  children: ({ onClose }: ChildrenProps) => React.ReactNode;
  appearTimeoutMs?: number;
  exitTimeoutMs?: number;
  portalId?: string;
  openFrom?: OpenFrom;
  disableOverlay?: boolean;
  disableScrollLock?: boolean;
  theme?: Partial<Theme>;
  onClose: () => void;
}

function Drawer({
  isOpen,
  children,
  appearTimeoutMs = Timeout.AppearTimeoutMS,
  exitTimeoutMs = Timeout.ExitTimeoutMS,
  portalId = 'modal-root',
  openFrom = 'right',
  theme = {},
  disableOverlay = false,
  disableScrollLock = false,
  onClose,
}: Props) {
  const { disableScrollbar, enableScrollbar } = useScrollbar();
  const { isMobile } = useMediaQuery();

  useEffect(() => {
    if (!disableScrollLock) {
      if (isOpen) {
        disableScrollbar();
      } else {
        const timeout = setTimeout(
          () => {
            enableScrollbar();
            clearTimeout(timeout);
          },
          isMobile ? 0 : Timeout.ScrollRestoreMS,
        );
      }
    }
  }, [isOpen, isMobile, disableScrollLock, disableScrollbar, enableScrollbar]);

  useEffect(
    () => () => {
      enableScrollbar();
    },
    [enableScrollbar],
  );

  return (
    <Context.Provider value={{ theme }}>
      {createPortal(
        <Transition
          in={isOpen}
          timeout={{
            appear: appearTimeoutMs,
            exit: exitTimeoutMs,
          }}
        >
          {(state: string) =>
            state !== 'exited' && (
              <Fragment>
                <Content isOpen={isOpen} openFrom={openFrom} state={state}>
                  {children({ onClose })}
                </Content>

                {!disableOverlay && <Overlay state={state} onClose={onClose} />}
              </Fragment>
            )
          }
        </Transition>,
        getPortalRoot(portalId),
      )}
    </Context.Provider>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Drawer;
